import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes, ModalOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setIsAutoSpins,
  setIsLeftHandMode,
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
  setSlotMachineInitialized,
} from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinsMode } from '../../utils';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';
import AutoResizeText from '../text/autoResizeText';

import {
  FEATURE_BTN_TEXT_PRESS_X_OFFSET,
  FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
  FEATURE_BTN_TEXT_X_OFFSET,
  FEATURE_BTN_TEXT_Y_OFFSET,
} from './config';
import {
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
} from './textStyles';

class BuyFeatureBtn extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  private isDisabled: boolean;

  private isLeftHandMode: boolean;

  private isFreeRoundBonus: boolean;

  private window: PIXI.ISize = { width: 0, height: 0 };

  constructor() {
    super();
    this.isDisabled = false;
    this.visible = !setBrokenGame();
    this.isLeftHandMode = setIsLeftHandMode();
    this.isFreeRoundBonus = false;

    this.btn = this.initBuyFeatureBtn();
    this.text = this.initBuyFeatureText();

    this.addChild(this.btn, this.text);

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.CHANGE_MODE, ({ mode }) => {
      this.visible = !isFreeSpinsMode(mode);
    });
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, ({ mode }) => {
      this.visible = !isFreeSpinsMode(mode);
    });
    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLhm: boolean) => {
      this.isLeftHandMode = isLhm;
      this.handlePosition();
    });
    eventManager.on(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.on(EventTypes.SET_IS_FREE_ROUND_BONUS, (isFreeRoundBonus: boolean) => {
      this.isFreeRoundBonus = isFreeRoundBonus;
      this.handleDisable(isFreeRoundBonus);
    });
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureMainBtn));
    btn.anchor.set(0.5);
    btn.interactive = true;
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', () => {
      if (!this.isDisabled) {
        AudioHowl.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMainBtnOver);

        this.text.style = buyFeatureHoverTextStyle;
        this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMainBtn);

        this.text.style = buyFeatureTextStyle;
        this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMainBtnClick);

        this.text.style = buyFeatureClickTextStyle;
        this.text.position.set(FEATURE_BTN_TEXT_PRESS_X_OFFSET, FEATURE_BTN_TEXT_PRESS_Y_OFFSET);
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMainBtn);

        this.text.style = buyFeatureTextStyle;
        this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
      }
    });
    return btn;
  }

  private initBuyFeatureText(): AutoResizeText {
    const text = new AutoResizeText(i18n.t<string>('buyFeature.button'), buyFeatureTextStyle);

    text.anchor.set(0.5);
    text.position.set(this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET, this.height / 2 + FEATURE_BTN_TEXT_Y_OFFSET);

    return text;
  }

  private onClick(): void {
    if (
      !this.isDisabled &&
      setIsModalOpeningInProgress() === ModalOpeningTypes.NONE &&
      !setIsAutoSpins() &&
      setSlotMachineInitialized()
    ) {
      setIsModalOpeningInProgress(ModalOpeningTypes.BUY_FEATURE_POPUP);
      setIsOpenAutoplayModal(false);
      setIsOpenBetSettingsModal(false);
      setIsOpenMenuModal({ isOpen: false, type: 'menu' });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
      AudioHowl.play({ type: ISongs.XT001S_PushBuyFeature });
      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isFreeRoundBonus && !disable) return;
    if (this.isAutoSpinInProgress) return;

    this.isDisabled = disable;
    if (disable) {
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMainBtnDisable);

      this.text.style = buyFeatureDisableTextStyle;
      this.text.position.set(FEATURE_BTN_TEXT_PRESS_X_OFFSET, FEATURE_BTN_TEXT_PRESS_Y_OFFSET);
    } else {
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMainBtn);

      this.text.style = buyFeatureTextStyle;
      this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private resize(width: number, height: number): void {
    this.window = { width, height };
    this.handlePosition();
  }

  private handlePosition(): void {
    if (this.window.width >= this.window.height) {
      this.pivot.x = this.isLeftHandMode ? -this.btn.width / 2 : this.btn.width / 2;
      this.pivot.y = 0;
      this.x = this.isLeftHandMode ? SLOTS_CONTAINER_WIDTH + 30 : -30;
      this.y = SLOTS_CONTAINER_HEIGHT / 2 + 130;
      this.scale.set(1);
    } else {
      this.pivot.x = this.isLeftHandMode ? this.btn.width / 2 : -this.btn.width / 2;
      this.pivot.y = -this.btn.height / 2;
      this.x = this.isLeftHandMode ? SLOTS_CONTAINER_WIDTH - 90 : 90;
      this.y = SLOTS_CONTAINER_HEIGHT + 100;
      this.scale.set(1.3);
    }
  }
}

export default BuyFeatureBtn;
