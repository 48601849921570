import { Container, Graphics, Sprite, Text, Texture } from 'pixi.js';

import SlotMachine from '..';
import { EventTypes } from '../../global.d';
import { setFreeRoundsTotalWin, setIsPopupFreeRoundsOpened } from '../../gql/cache';
import i18n from '../../i18next';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import { LAYOUT_OPTIONS, buttonTextStyle, freeRoundsTextStyles } from './config';
import { updateCoinValueFromFreeRoundBonus } from './helper';

export class FreeRoundsPopup extends ViewContainer {
  protected popup: Container;

  protected background: Sprite;

  protected freeRounds: Text;

  protected roundsLabel: Text;

  protected roundsAmount: Text;

  protected isTriggerFreeSpin = false;

  protected backgroundFadeInAnimation: Tween;

  protected backgroundFadeOutAnimation: Tween;

  public isOnceOpened = false;

  private bindedCallback = () => {
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
    this.backgroundFadeOutAnimation.start();
    this.visible = false;
    setIsPopupFreeRoundsOpened(false);
  };

  constructor() {
    super();
    this.freeRounds = this.initFreeRoundsText();
    this.roundsAmount = this.initRoundsAmount();
    this.roundsLabel = this.initRoundsLabel();
    this.background = this.initBackground();
    this.popup = this.initPopup();
    this.backgroundFadeInAnimation = this.initBackGroundFadeInAnimation();
    this.backgroundFadeOutAnimation = this.initBackGroundFadeOutAnimation();

    this.init();

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.OPEN_POPUP_FREE_ROUNDS, this.show.bind(this));
  }

  protected init() {
    this.addChild(this.background);
    this.addChild(this.popup);

    const app = SlotMachine.getInstance().getApplication();
    // Forced layer designation(Between the bottom bar and other buttons)
    app.stage.addChildAt(this, 2);
    this.visible = false;
  }

  protected initPopup(): Container {
    const container = new Container();
    const innerBg = new Graphics()
      .beginFill(LAYOUT_OPTIONS.bgColor)
      .drawRoundedRect(0, 0, LAYOUT_OPTIONS.width, LAYOUT_OPTIONS.height, LAYOUT_OPTIONS.borderRadius)
      .endFill();
    innerBg.x = 2;
    innerBg.y = 2;
    const outerBg = new Graphics()
      .beginFill(LAYOUT_OPTIONS.borderColor)
      .drawRoundedRect(
        0,
        0,
        LAYOUT_OPTIONS.width + 2 * LAYOUT_OPTIONS.border,
        LAYOUT_OPTIONS.height + 2 * LAYOUT_OPTIONS.border,
        LAYOUT_OPTIONS.borderRadius,
      )
      .endFill();
    const closeBtn = this.createButton();

    container.addChild(outerBg, innerBg, closeBtn, this.freeRounds, this.roundsAmount, this.roundsLabel);
    closeBtn.x = LAYOUT_OPTIONS.width / 2 - closeBtn.width / 2;
    closeBtn.y = LAYOUT_OPTIONS.height - (closeBtn.height * 3) / 2;

    return container;
  }

  private initBackground(): Sprite {
    const sprite = new Sprite(Texture.WHITE);
    sprite.tint = 0x000000;
    sprite.anchor.set(0.5, 0.5);
    sprite.alpha = 0.5;
    return sprite;
  }

  private initRoundsLabel() {
    const text = new Text(i18n.t<string>('freeRoundsLabel'), freeRoundsTextStyles);

    text.anchor.set(0.5, 0.5);
    text.position.set(215, 57);
    return text;
  }

  private initRoundsAmount() {
    const text = new Text('', {
      ...freeRoundsTextStyles,
      fontSize: 50,
    });
    text.anchor.set(0.5, 0.5);
    text.position.set(215, 115);
    return text;
  }

  private initFreeRoundsText() {
    const text = new Text(i18n.t<string>('freeRounds'), freeRoundsTextStyles);
    text.anchor.set(0.5, 0.5);
    text.position.set(215, 175);
    return text;
  }

  private initBackGroundFadeInAnimation() {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 0.7,
      duration: 500,
    });
  }

  private initBackGroundFadeOutAnimation() {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0.7,
      target: 0,
      duration: 500,
    });
  }

  public show(rounds: number): void {
    setIsPopupFreeRoundsOpened(true);
    this.roundsAmount.text = `${rounds}`;
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
    this.visible = true;
    this.backgroundFadeInAnimation.start();
    this.isOnceOpened = true;
    if (setFreeRoundsTotalWin() > 0) {
      setTimeout(() => {
        eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
      });
    }
    updateCoinValueFromFreeRoundBonus();
  }

  private createButton(): Container {
    const width = LAYOUT_OPTIONS.width / 3;
    const height = LAYOUT_OPTIONS.width / 8;
    const borderWidth = 3; // Define the border width
    const { borderColor } = LAYOUT_OPTIONS; // Define the border color
    const radius = LAYOUT_OPTIONS.borderRadius; // Border radius

    const button = new Graphics();

    // Draw the specific borders (only top-right and bottom)
    button
      .lineStyle(borderWidth, borderColor)
      .moveTo(width - radius, 0) // Start drawing at the top-right corner
      .arcTo(width, 0, width, radius, radius) // Top-right corner arc
      .lineTo(width, height - radius) // Right border downwards
      .arcTo(width, height, width - radius, height, radius) // Bottom-right corner
      .lineTo(radius, height) // Draw the bottom border
      .arcTo(0, height, 0, height - radius, radius); // Bottom-left corner arc

    // Draw the filled button
    button.beginFill(LAYOUT_OPTIONS.buttonColor).drawRoundedRect(0, 0, width, height, radius).endFill();

    const text = new Text(i18n.t<string>('close'), buttonTextStyle);
    text.anchor.set(0.5);
    text.position.set(width / 2, height / 2);

    button.interactive = true;
    button.cursor = 'pointer';
    button.on('pointertap', () => this.bindedCallback());
    button.addChild(text);
    return button;
  }

  private resize(width: number, height: number): void {
    this.background.width = width;
    this.background.height = height;
    this.background.position.set(width / 2, height / 2);

    if (width - 50 < LAYOUT_OPTIONS.width) {
      this.popup.scale.set(width / (LAYOUT_OPTIONS.width + 50));
    } else if (height - 100 < LAYOUT_OPTIONS.height) {
      this.popup.scale.set(height / (LAYOUT_OPTIONS.height + 100));
    } else {
      this.popup.scale.set(1);
    }
    this.popup.y = height / 2 - this.popup.height / 2;
    this.popup.x = width / 2 - this.popup.width / 2;
  }
}
